import React from 'react';
import {GlobalNavigationHeader, GlobalNavigationFooter, GlobalNavigationProvider} from '@hy-vee/global-navigation';
import {useRouter} from 'next/router';
import {CartProvider} from '@hy-vee/react-web-and-mobile-ui-components';

import {StyledFixedContainer, StyledRelativeContainer} from '../styles/style-constants';
import {HeaderChildren} from '../styles/header-styles';
import {useSubNavProps} from '../utils/subnav-utils';
import {useAislesOnlineCart} from '../hooks/use-cart';
import {useStore} from '../hooks/use-store';
import {
    getLocationText,
    handleDataChange
} from '../helpers/global-nav-helpers';
import {info} from '../services/logging-service';
import {HYFISH_WEB_FOOTER, HYFISH_WEB_HEADER, SEASONS_SEARCH, SEASONS_SEARCH_IDEAS} from '../enums/feature-toggle-names';
import {useFeatureToggle} from '../contexts/feature-toggle';
import {login} from '../utils/navigation-helpers';
import {useCustomerContext} from '../contexts/customer-context';

import {useModalContext} from './modals/modal-provider';

const HeaderPage = ({
    background = false,
    children,
    sticky = false
}: any) => {
    const HeaderStickyContainer = sticky ? StyledFixedContainer : StyledRelativeContainer;

    const router = useRouter();

    const handleRouteChange = (url) => {
        router.push(url);
    };

    const {isAuthenticated, loading} = useCustomerContext();
    const {featureEnabled, loading: featureToggleLoading} = useFeatureToggle();
    const useHyfishWebFooter = featureEnabled(HYFISH_WEB_FOOTER);
    const useHyfishWebHeader = featureEnabled(HYFISH_WEB_HEADER);

    const isSeasonsSearchFeatureEnabled = featureEnabled(SEASONS_SEARCH);
    const isEcommIdeasEnhancedSearchWeb = featureEnabled(SEASONS_SEARCH_IDEAS);

    const {storeSelector} = useModalContext();
    const onStoreChange = () => {
        if (loading || featureToggleLoading || !isAuthenticated) {
            login(router.asPath);
        }

        storeSelector.show();
    };
    const {data: cart} = useAislesOnlineCart(true);
    const {data: store} = useStore();

    const fulfillmentConfig = {
        fulfillmentExpiration: cart?.fulfillmentExpiration,
        fulfillmentTime: {
            windowEnd: cart?.fulfillmentWindowEnd,
            windowStart: cart?.fulfillmentWindowStart
        },
        fulfillmentType: cart?.fulfillmentType,
        locationText: getLocationText(cart, store),
        onStoreChange
    };

    const subnavProps = useSubNavProps();
    const clientPaths = {
        apiPrefix: '/deals/api',
        loginPath: '/deals/login',
        logoutPath: '/deals/logout'
    };

    const logErrorMessage = (error) => info(router.pathname, 'Global Nav Error', error);

    return (
        <div style={{minWidth: 400}}>
            <GlobalNavigationProvider
                clientPaths={clientPaths}
                onGlobalNavError={logErrorMessage}
            >
                <CartProvider
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    cart={cart}
                >
                    <StyledRelativeContainer background={background}>
                        <HeaderStickyContainer className="hiddenForPrint">
                            <div
                                style={{
                                    backgroundColor: '#e8e8e8',
                                    paddingBottom: 1
                                }}
                            >
                                <GlobalNavigationHeader
                                    featureFlagHeaderRed={useHyfishWebHeader}
                                    isEcommIdeasEnhancedSearchWeb={isEcommIdeasEnhancedSearchWeb}
                                    isSeasonsSearchFeatureEnabled={isSeasonsSearchFeatureEnabled}
                                    onDataChange={handleDataChange}
                                    onRouteChange={handleRouteChange}
                                    storeConfig={fulfillmentConfig}
                                    subnavProps={subnavProps}
                                />
                            </div>
                        </HeaderStickyContainer>
                        <HeaderChildren
                            sticky={sticky}
                        >
                            {children}
                        </HeaderChildren>
                    </StyledRelativeContainer>
                    <div className="hiddenForPrint">
                        <GlobalNavigationFooter featureFlagFooterRed={useHyfishWebFooter} />
                    </div>
                </CartProvider>
            </GlobalNavigationProvider>
        </div>
    );
};

export default HeaderPage;
